import { MultiStepForm } from './core/MultiStepForm';
import { SingleStepForm } from './core/SingleStepForm';
import {
  localConfig,
  testConfig,
  applicationConfig,
  boomContact,
  boomContactOther,
  boomDaSyllabus,
  boomCaseStudy,
} from './formConfigs';

function initForm(wrapper: HTMLElement, form: HTMLFormElement) {
  let formConfig;
  const formConfigType = wrapper.getAttribute('fl-form-config');
  switch (formConfigType) {
    case 'test':
      formConfig = testConfig;
      break;
    case 'application':
      formConfig = applicationConfig;
      break;
    case 'boom-contact':
      formConfig = boomContact;
      break;
    case 'boom-contact-other':
      formConfig = boomContactOther;
      break;
    case 'boom-da-syllabus':
      formConfig = boomDaSyllabus;
      break;
    case 'boom-case-study':
      formConfig = boomCaseStudy;
      break;
    default:
      formConfig = localConfig;
      break;
  }

  const type = wrapper.getAttribute('fl-type');
  switch (type) {
    case 'multi-step':
      return new MultiStepForm(wrapper, form, formConfig);
    default:
      return new SingleStepForm(wrapper, form, formConfig);
  }
}

document.querySelectorAll('[fl="form"]').forEach(wrapper => {
  const form = wrapper.querySelector('form') as HTMLFormElement;
  initForm(wrapper as HTMLElement, form);
});
